import React from "react"
import { Link } from "gatsby"
import { Nav, NavDropdown } from "react-bootstrap"

export default ({ toggle }) => (
  <>
    <button className="close-sidebar-btn" onClick={toggle}>
      <i className="fa fa-times"></i>
    </button>
    <div className="navigation">
      <Nav className="flex-column">
        <div className="container-fluid pt-3 mt-3 mb-5 ">
          <div className="row justify-content-center">
            <div className="text-center text-white">
              <img
                className="img-fluid nav-logo"
                src={require("@images/logo-text-2.png")}
                width="100%"
                alt="Fabricating Equipment Sales Co. Logo 2"
              />
            </div>
          </div>
        </div>
        <Nav.Item>
          <Link to="/">Home</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/company">Company</Link>
        </Nav.Item>
        <NavDropdown title="Application">
          <NavDropdown.Item href="https://www.fabricatingequipmentsales.com/products/general-fabrication">
            General Fabrication
          </NavDropdown.Item>
          <NavDropdown.Item href="https://www.fabricatingequipmentsales.com/products/precision-sheet-metal">
            Precision Sheet Metal
          </NavDropdown.Item>
          <NavDropdown.Item href="https://www.fabricatingequipmentsales.com/products/product-manufacturing">
            Product Manufacturing
          </NavDropdown.Item>
          <NavDropdown.Item href="https://www.fabricatingequipmentsales.com/products/steel-service-centers">
            Steel Service Centers
          </NavDropdown.Item>
          <NavDropdown.Item href="https://www.fabricatingequipmentsales.com/products/structural-steel">
            Structural Steel
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Item>
          <Link to="/products">Products</Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Link to="/used-equipment">Used Equipment</Link>
        </Nav.Item> */}
        <Nav.Item>
          <Link to="/contact">Contact Us</Link>
        </Nav.Item>
      </Nav>

      <div className="contacts">
        <p className="text-white text-uppercase">Call Us Today</p>
        <ul className="list-unstyled contact my-4">
          <li className="phone">
            <a href="tel:303-466-7341">Office: (303) 466-7341</a>
          </li>
          <li className="mobile">
            <a href="tel:303-550-0734">Mobile: (303) 550-0734</a>
          </li>
        </ul>
        <ul className="list-inline social-icons-list mt-3">
          <li className="list-inline-item">
            <a
              href="http://www.facebook.com/FabEquipSalesCo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a
              href="http://www.linkedin.com/company/2154517"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </>
)
