import React from "react"
import { Link } from "gatsby"
import WebriQForm from "@webriq/gatsby-webriq-form"
// import WebriqForm from "./form/form"

const footer = props => {
  return (
    <footer>
      <div className="footer-cta">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 text-center text-md-left">
              <h2 className="text-white h3">
                Specializing Exclusively in Structural Steel and Sheet Metal
                Fabricating Equipment
              </h2>
              <h3
                style={{ fontSize: "1rem", lineHeight: "1.6" }}
                className="text-white-50 mb-0"
              >
                Give us a call and we'll assist in finding the most suitable,
                cost-effective fabricating equipment for your needs.
              </h3>
            </div>
            <div className="col-md-6 text-center text-md-right mt-4 mt-md-0">
              <div>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="display-5 font-weight-bold text-white phone-num-cta"
                      href="tel:303-466-7341"
                    >
                      (303) 466-7341
                    </a>
                  </li>
                  <li>
                    <a
                      className="h3 font-weight-bold text-white phone-num-cta"
                      href="tel:303-550-0734"
                    >
                      Jim: (303) 550-0734
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center justify-content-center justify-content-md-end mt-3">
                <span className="pr-3 font-italic font-weight-normal h5 text-white m-0">
                  or
                </span>
                <Link className="btn btn-primary btn-red" to="/contact">
                  Send Us a Message
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-main">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-2 mb-4 mb-md-0">
              <h2 className="text-uppercase font-weight-bold text-white h6">
                Quick Links
              </h2>
              <ul className="list-unstyled footer-details-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/company">Company</Link>
                </li>
                <li>
                  <Link to="/products">Products</Link>
                </li>
                {/* <li>
                  <Link to="/used-equipment">Used Equipment</Link>
                </li> */}
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-4 mb-md-0">
              <h2 className="text-uppercase font-weight-bold text-white h6">
                Our Office
              </h2>
              <ul className="list-unstyled footer-details-list">
                <li className="address">
                  Fabricating Equipment Sales
                  <br />
                  1151 Eagle Dr box 157
                  <br />
                  Loveland Colorado 80537
                  {/*<a
                    className="small"
                    href="https://www.google.com/maps/place/7130+W+117th+Ave+Unit+D-4,+Broomfield,+CO+80020/@39.909582,-105.075343,14z/data=!4m5!3m4!1s0x876b8a4a4551e183:0xa41424ced32d1648!8m2!3d39.9095275!4d-105.0753779?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Directions <i className="fa fa-external-link small"></i>
                  </a>*/}
                  <br />
                </li>
                <li className="hours">
                  Monday - Friday
                  <br />
                  7:00 AM - 5:00PM, MST
                  <br />
                </li>
              </ul>
            </div>
            <div className="col-md-2 mb-4 mb-md-0">
              <h2 className="text-uppercase font-weight-bold text-white h6">
                Connect with Us
              </h2>
              <ul className="list-unstyled footer-details-list">
                <li className="facebook">
                  <a
                    href="http://www.facebook.com/FabEquipSalesCo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li className="linkedin">
                  <a
                    href="http://www.linkedin.com/company/2154517"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h2 className="text-uppercase font-weight-bold text-white h6">
                Newsletter
              </h2>
              <span className="small d-block mb-3">
                Subscribe to get our latest news and updates straight to your
                inbox!
              </span>
              <WebriQForm
                method="POST"
                data-form-id="333737da-2e5a-49d0-a15c-5199276e8804"
                name="Subscription Form"
                className="email-subscription-input"
                data-thankyou-url="/subscribed"
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    name="Email"
                    placeholder="Your email address"
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group">
                  <button className="btn btn-primary btn-sm" type="submit">
                    Subscribe
                  </button>
                </div>
              </WebriQForm>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="d-md-flex justify-content-md-between align-items-center text-center">
            <span className="d-block small">
              © {new Date().getFullYear()} Fabricating Sales Equipment Co. All
              rights reserved.
            </span>
            <span className="d-block small">
              Designed and powered by{" "}
              <a
                href="https://www.webriq.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                WebriQ
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
